import { type DropdownMenuCheckboxItemProps } from '@radix-ui/react-dropdown-menu';
import { type VariantProps, cva } from 'class-variance-authority';
import { type ColumnDef } from '@tanstack/react-table';

import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export { DropdownMenuCheckboxItemProps, cva, type VariantProps, ColumnDef, ClassValue };
