import { useEffect, useState } from 'react';
import { useAsyncError, useRouteError } from 'react-router';
import * as Sentry from '@sentry/react';
import { ApiErrorInterface, ApiErrorResponse, ApplicationError } from '@apps/admin-portal-web/src/types/error';
import { CopyValue } from '@apps/admin-portal-web/src/components/CopyValue';

export const extractErrorMessage = async (apiError: ApiErrorInterface) => {
    try {
        const jsonResponse = await (apiError.response.clone().json() as Promise<ApiErrorResponse>);
        return jsonResponse.message;
    } catch {
        return apiError.message || 'Unexpected error';
    }
};

export function ErrorComponentBoundary() {
    const [eventId, setEventId] = useState<string>();
    const error: ApplicationError = useRouteError() as ApiErrorInterface;
    const asyncError = useAsyncError();
    const [errorMessage, setErrorMessage] = useState<string>();

    const consolidatedError = error || asyncError;
    useEffect(() => {
        if (consolidatedError) {
            // if the error comes from the API, add the corresponding Sentry tags
            if (consolidatedError.type === 'ApiError') {
                const apiError = consolidatedError as ApiErrorInterface;
                const correlationId = apiError.response.headers.get('x-correlation-id');
                Sentry.setTag('correlationId', correlationId);
                Sentry.setTag('workspace', apiError.workspace);
                Sentry.setTag('componentId', apiError.componentId);

                extractErrorMessage(apiError).then(message => {
                    setErrorMessage(message);
                });
            } else {
                setErrorMessage(consolidatedError.message);
            }
            const newEventId = Sentry.captureException(consolidatedError);
            setEventId(newEventId);
        }
    }, [consolidatedError]);

    return (
        <div data-testid="errorComponentBoundary" className="m-8 border border-b border-solid p-8 shadow">
            <div className="flex flex-row items-center justify-between">
                <p className="font-bold">{errorMessage}</p>
                <span className="text-5xl">&#128054;</span>
            </div>
            <div className="mb-4 mt-4">
                {eventId && (
                    <div className="text-gray">
                        Event Id{' '}
                        <CopyValue
                            displayValue={eventId.substring(0, 5)}
                            value={eventId}
                            label="Event id"
                            variant="outline"
                        />
                    </div>
                )}
            </div>
            <div className="text-xs">
                If you think this is a bug, please contact the support team and include the &quot;Event Id&quot;
            </div>
        </div>
    );
}
